.pills .pil {
    background: var(--bs-lt-red);
    padding: 5px 11px 5px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    display: inline-flex;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1em;
}

.pills .pildk {
    background: var(--bs-dk-grey);
    padding: 5px 11px 5px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    display: inline-flex;
    font-size: 0.9em;
    font-weight: 700;
    line-height: 1em;
}

.pills .pil .text,.pills .pildk .text {
    display: flex;
    align-self: center;
    line-height: 1.1em;
    padding-top: 2px;
    color: var(--bs-black);
}