.inputValue {
  width: 100%;
  padding: 5px 12px;
  color: var(--bs-grey);
  background-color: var(--bs-black);
  font-size: 0.9em;
  border: none;
  text-align: left;
}

.timeContainer {
  position: absolute;
  background-color: var(--bs-dk-black);
  padding: 5px;
  top: -155px;
  left: 9vw;
}

.timeList {
  display: flex;
  border-radius: 5px;
  width: fit-content;
  margin: 0px 10px;
}

.timeList ul {
  padding-left: 0rem;
  margin-bottom: 0rem;
  height: 200px;
  overflow: scroll;
  margin: 0px 5px;
}

.timeList ul li {
  list-style: none;
  color: var(--bs-white);
  font-weight: bold;
  padding: 5px 20px;
  margin: 0px 2px;
  border-radius: 5px;
  text-align: center;
}

.isActiveItem {
  background: var(--bs-lt-red);
  color: var(--bs-black) !important;
}

.timeList ul li :hover {
  background-color: rgb(143 213 245);
}


.timeList ul::-webkit-scrollbar {
  display: none;
}


.inputValue:focus {
  outline: none;
  border: none;
  color: white;
  font-size: 1em;
  padding: 4px 12px;
}

/* .modal .modal-content {
  width: auto;
}

.modal-dialog {
  max-width: var(--bs-modal-width);
  margin-right: auto;
  margin-left: auto;
  width: fit-content !important;
} */