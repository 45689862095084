.title {
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}