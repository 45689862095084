.btnWidthTrue {
  width: 95%;
  padding-left: 17px;
  padding-bottom: 10px;
  color: var(--bs-grey) !important;
  font-size: 16;
}

.btnWidthFalse {
  width: 85%;
  padding-left: 17px;
  padding-bottom: 10px;
  color: var(--bs-grey) !important;
  font-size: 16;
}
