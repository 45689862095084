.weSelect {
  background-color: transparent;
  color: white;
  height: 3.5rem;
}

.list-group {
  /* max-height: 60vh !important; */
  overflow-y: scroll !important;
  --bs-list-group-color: white !important;
  --bs-list-group-bg: #2a2a2a !important;
  --bs-list-group-border-color: white !important;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-action-color: #ff5c5c !important;
  --bs-list-group-active-bg: #ff5c5c !important;
  --bs-list-group-active-border-color: #ff5c5c !important;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group li {
  border-right: 0;
  border-left: 0;
  padding: 0.8rem;
}

.list-group li:nth-child(1) {
  border-top: 0 none;
}

.list-group li:last-child {
  border: none;
}

.btn-file {
  position: relative;
  overflow: hidden;
  background-color: #FF5C5C;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #FF5C5C;
  cursor: inherit;
  display: block;
}

input.picker[type="date"] {
  position: relative;
}

input.picker[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background: transparent;
}

 /* .banner {
  padding: 0px !important;
} */

/* .banner .content {
  max-height: 200px;
  width: 35em;
}

.banner img {
  object-fit: cover !important;
  max-height: 200px;
  width: 100%;
}  */

.dragImg {
  position: absolute;
  top: 33%;
  left: 37%;
}

.list-group-flush {
  border-radius: 0;
  z-index: 50;
}