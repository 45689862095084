#imageUpload {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.modal-title {
  width: 100%;
}

.closeBtn {
  margin-left: auto;
  color: var(--bs-lt-red);
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

.task {
  color: #000;
}

.contain {
  width: 30vw !important;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  background-color: #f2efee;
  border-radius: 11px;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
}

.login-form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.login-form label {
  display: block;
  margin-bottom: 8px;
}

.login-form input {
  width: 100%;
  padding: 0.5rem;
  border-radius: 9px;
  border: none;
}

.login-form input:focus {
  outline: none;
  box-shadow: 0 0 0 0.5px var(--bs-lt-red);
  ;
}

.text-warning {
  border: 1px solid red !important;
}

.linkText {
  color: var(--bs-lt-red);
  cursor: pointer;
}

.text {
  color: #504a4a;
  text-align: center;
}

.rightText {
  margin-left: auto;
}

.centerText {
  text-align: center;
}

/* .icon {
  font-size: 1.5rem;
  margin: 10px;
  cursor: pointer;
} */

.appleIcon {
  color: #000;
}

.instagramIcon {
  color: #d6249f;
}

.googleIcon {
  color: #4285F4;
}

/* .btn {
  border: none !important;
}

.dropdown.select .dropdown-button {
  padding-left: 24px !important;
}

.prefix {
  position: absolute;
  top: 17px;
  left: 12px;
  color: #b7b9bb;
}

.input-block input,
.input-block textarea {
  padding-left: 24px;
}

@media (max-width: 991px) {
  .navbar-brand img {
    width: 50px;
    height: 50px;
  }
} */