:root {
	--bs-primary: #2179fd;
	--bs-lt-red: #ff8282;
	--bs-red: #ff5c5c;
	--bs-dk-red: #ed2424;
	--bs-redwood: #b1504f;
	--bs-lt-grey: #dddddd;
	--bs-grey: #c5c5c5;
	--bs-dk-grey: #606060;
	--bs-white: #fff;
	--bs-lt-black: #2a2a2a;
	--bs-black: #1e1e1e;
	--bs-dk-black: #0f0f0f;
	--bs-transparent: #0f0f0f00;
}

$mobile: 576px;
$tablet: 991px;

$heading-font-family: "Inter", sans-serif;
$body-font-family: "Josefin Sans", sans-serif;

@mixin scrollbar {
	/***** SCROLL BAR ****/
	&::-webkit-scrollbar {
		width: 7px;
		border-radius: 7px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: var(--bs-grey);
		border-radius: 5px;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--bs-dk-grey);
		border-radius: 5px;
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: var(--bs-black);
	}
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Josefin+Sans:wght@400;700&display=swap");

img {
	object-fit: cover;
	width: 100%;
}

body {
	min-height: 100vh;
	background-color: var(--bs-black);
	color: var(--bs-white);
	font-family: $body-font-family;
	font-size: 16px;
	@media (max-width: $mobile) {
		font-size: 14px;
	}
}
h1,
h2,
h3,
h4,
h5 {
	font-family: $heading-font-family;
	font-weight: 900;
	margin-bottom: 0.3em;
}
h1 {
	font-size: 3.5em;
	@media (max-width: $tablet) {
		font-size: 2.1em;
	}
}
h2 {
	font-size: 2.5em;
	@media (max-width: $tablet) {
		font-size: 1.55em;
	}
}
h3 {
	font-size: 1.9em;
	@media (max-width: $tablet) {
		font-size: 1.3em;
	}
}
h4 {
	font-size: 1.3em;
	@media (max-width: $tablet) {
		font-size: 1.15em;
	}
}
h5 {
	font-size: 1.1em;
	@media (max-width: $tablet) {
		font-size: 1em;
	}
}

hr {
	border-top: 2px solid var(--bs-lt-black);
	opacity: 1;
	margin: 2em 0;
	@media (max-width: $tablet) {
		margin: 2em 0;
	}
	@media (max-width: $mobile) {
		margin: 1.4em 0;
	}
}

a {
	color: var(--bs-lt-red);
	text-decoration: none;
	transition: 0.15s ease-in-out;
	&.active,
	&:hover,
	&:focus,
	:active {
		color: var(--bs-red);
	}
}
.white {
	color: var(--bs-white) !important;
}
p {
	line-height: 1.6em;
	max-width: 700px;
}
code {
	color: var(--bs-primary);
}

.hamburger {
	display: inline-block;
	cursor: pointer;

	.bar-line {
		border-radius: 10px;
	}

	.bar1,
	.bar2,
	.bar3 {
		width: 27px;
		height: 4px;
		background-color: #fff;
		margin: 5px 0;
		transition: 0.2s ease-in-out;
		margin-left: auto;
	}

	.bar1 {
		width: 27px;
	}
	.bar2 {
		width: 21px;
	}
	.bar3 {
		width: 24px;
	}

	&.change .bar1 {
		width: 27px;
		transform: translate(0, 9px) rotate(135deg);
	}

	&.change .bar2 {
		width: 27px;
		opacity: 0;
	}

	&.change .bar3 {
		width: 27px;
		transform: translate(0, -9px) rotate(-135deg);
	}
}

.navbar-brand {
	text-align: center;
	font-size: 17px;
	@media (max-width: $tablet) {
		text-align: left;
		font-size: 17px;
	}
	img {
		width: 85px;
		@media (max-width: $tablet) {
			width: 50px;
		}
	}
	a {
		color: var(--bs-white);
	}
}

.form-check-input {
	&:checked {
		background-color: var(--bs-red);
		border-color: var(--bs-red);
	}
	&:focus {
		box-shadow: 0 0 0 0.25rem #ff5c5c40;
	}

	&.order-checkbox {
		margin-bottom: 6px;
	}
}

input.form-check-input[type="radio"] + .form-check-label,
input.form-check-input + .form-check-label {
	cursor: pointer;
}

.accordion {
	border: none;
	.accordion-item {
		border-color: var(--bs-dk-black);
		background-color: var(--bs-black);
		.accordion-button {
			background-color: var(--bs-lt-black);
			border-color: var(--bs-dk-black);
			color: var(--bs-white);
			display: flex;
			align-items: center;
			justify-content: space-between;
			outline: 0;
			box-shadow: none;
			&::after {
				display: none;
				font: var(--fa-font-solid);
				content: "\f107";
			}
			svg {
				transform: rotate(0deg);
				transition: 0.2s ease-in-out;
			}
			&.collapsed {
				svg {
					transform: rotate(180deg);
				}
			}
		}
		.accordion-body {
			color: var(--bs-white);
		}
	}
}

.admin-left-column {
	border-right: 1px solid #141414;
	background: #161616;
	box-shadow: 5px 2px 22px rgba(0, 0, 0, 0.45);
	min-height: 100vh;
	max-width: 300px;
	@media (max-width: $tablet) {
		border-right: initial;
		background: initial;
		box-shadow: none;
		min-height: initial;
		max-width: initial;
	}
}

.input-block {
	border: 2px solid var(--bs-grey);
	border-radius: 8px;
	margin-top: 25px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 2px;
	transition: 0.15s ease-in-out;
	width: 100%;
	label {
		background: var(--bs-black);
		color: var(--bs-grey);
		display: inline-flex;
		padding: 0px 5px;
		font-size: 16px;
		margin-top: -10px;
		margin-bottom: -3px;
		margin-left: 8px;
		position: relative;
		transition: 0.15s ease-in-out;
		z-index: 10;
		&:after {
			content: "";
			background: var(--bs-black);
			bottom: 0;
			left: 0;
			position: absolute;
			transition: 0.15s ease-in-out;
			height: 50%;
			width: 100%;
			z-index: -1;
		}
	}
	input,
	textarea {
		border: none;
		border-radius: 8px;
		background-color: var(--bs-black);
		color: var(--bs-grey);
		transition: 0.15s ease-in-out;
		font-size: 0.9em;

		@media (max-width: $mobile) {
			padding: 0.25rem 0.5rem;
		}
		&:focus {
			border: none;
			box-shadow: none;
			background: var(--bs-white);
			outline: 0;
		}
	}
	&:focus-within {
		border-color: var(--bs-white);
		outline: 0;
		background-color: var(--bs-black);
		input,
		textarea {
			background-color: var(--bs-black);
			color: var(--bs-white);
		}
		label {
			color: var(--bs-white);
			margin-top: -9px;
			padding-bottom: 2px;
			font-size: 14px;
			&:after {
				background: var(--bs-black);
			}
		}
		.input-group {
			.input-group-text {
				color: var(--bs-white);
			}
		}
	}

	.input-group {
		.input-group-text {
			background: none;
			border: none;
			color: var(--bs-grey);
			margin-top: 1px;
			padding: 0;
			padding-left: 12px;
			align-self: center;
			z-index: 6;
			transition: 0.15s ease-in-out;
			@media (max-width: $mobile) {
				font-size: 14px;
				padding-left: 0.5rem;
			}
		}
		.input-group-text + .form-control {
			padding-left: 5px;
		}
	}

	/*** DISABLED ***/
	&.disabled {
		border-color: var(--bs-dk-grey);
		padding-top: 1px;
		label {
			color: var(--bs-dk-grey);
		}
		input {
			background: var(--bs-black);
			color: var(--bs-dk-grey);
		}
		.input-group-text {
			color: var(--bs-dk-grey);
		}
	}

	ul.list-group {
		max-height: 175px !important;
		overflow: hidden;
		height: initial;
		margin-top: 7px;
		border: 1px solid var(--bs-dk-black);
		.list-group-item {
			background: var(--bs-black);
		}
	}
}

.attendee-information-table {
	margin-top: 10px;
	margin-bottom: 40px;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	&.attendee-information-table > .row {
		border-bottom: 1px dashed var(--bs-dk-grey);
		padding: 10px 0;
		& > .col-5 > .row {
			height: 100%;
			display: flex;
			align-items: center;
		}
	}
	.move {
		display: flex;
		align-items: center;
		a {
			padding: 7px;
			padding-left: 0px;
		}
	}
}

.sidebar-nav {
	position: sticky;
	top: 20px;
	@media (max-width: $tablet) {
		position: initial;
		top: 0px;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		@media (max-width: $tablet) {
			position: absolute;
			top: 64px;
			left: 0;
			width: 100%;
			height: 100vh;
			background: var(--bs-black);
			z-index: 100;
			padding: 0 12px;
			opacity: 0;
			z-index: -1;
			visibility: hidden;
			transition: 0.15s ease-in-out;
		}

		&.show {
			opacity: 1;
			z-index: 100;
			visibility: visible;
		}

		li {
			a {
				text-transform: capitalize;
				border-radius: 10px;
				display: flex;
				width: 100%;
				padding: 12px 10px;
				text-decoration: none;
				color: var(--bs-white);
				margin-bottom: 7px;
				align-items: center;
				transition: 0.1s ease-in-out;
				font-size: 1.2em;
				cursor: pointer;
				@media (max-width: $tablet) {
					padding: 10px 0px;
				}

				&.active,
				&:hover,
				&:focus,
				:active {
					background-color: var(--bs-dk-black);
				}

				.fa-stack {
					color: var(--bs-dk-black);
					margin-right: 6px;
					.fa-inverse {
						color: var(--bs-lt-red);
					}
				}
				.title {
					font-size: 0.85em;
					font-weight: 700;
					padding-top: 4px;
				}
			}
		}
	}
}

.home-block {
	background: var(--bs-dk-black);
	border-radius: 12px;
	margin-bottom: 20px;
	padding: 15px;
	min-height: 360px;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	@media (max-width: $tablet) {
		min-height: 370px;
	}
	@media (max-width: 767px) {
		min-height: 250px;
	}
	@media (max-width: $mobile) {
		margin-bottom: 12px;
	}

	&:before {
		display: none;
	}
	.svg-inline--fa:not(.icon) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 48%;
		color: #1e1e1e63;
		z-index: 1;
		pointer-events: none;
		transition: 0.2s ease-in-out;
	}

	&:hover,
	&:focus,
	:active {
		.svg-inline--fa:not(.icon) {
			color: #2d2d2d63;
			transform: rotate(3deg) scale(1.2);
		}
	}

	&.revenue {
		&::before {
			font: var(--fa-font-solid);
			content: "\f81d";
		}
	}
	&.tickets-sold {
		&::before {
			font: var(--fa-font-solid);
			content: "\f145";
		}
	}
	&.add-ons-sold {
		&::before {
			font: var(--fa-font-solid);
			content: "\f890";
		}
	}
	&.total-views {
		&::before {
			font: var(--fa-font-solid);
			content: "\f643";
		}
	}
	&.shareable {
		&::before {
			font: var(--fa-font-solid);
			content: "\f1e0";
		}
	}

	.content {
		z-index: 2;
		margin-bottom: auto;
		.title {
			font-weight: 700;
			font-size: 0.9em;
		}
		.number {
			font-size: 1.5em;
			font-family: $heading-font-family;
		}
	}

	.share-block {
		display: flex;
		margin-top: -5px;
		align-items: center;
		.url {
			span {
				margin-left: 2px;
				padding: 10px 5px;
				background-color: var(--bs-lt-black);
				border-radius: 5px;
				outline: 0;
			}
		}
		.icon {
			display: flex;
			align-items: center;
			margin-left: 7px;
			font-size: 1.2em;
		}
	}

	.input-block {
		margin-right: 8px;
		border-radius: 8px;
		background-color: transparent;
		label {
			background: var(--bs-dk-black);
			color: var(--bs-grey);
			&:after {
				background: var(--bs-dk-black);
			}
		}
		input,
		textarea {
			border-radius: 8px;
			background-color: transparent;
			color: var(--bs-grey);
			&:focus {
				background: var(--bs-white);
			}
		}
		&:focus-within {
			border-color: var(--bs-white);

			background-color: var(--bs-dk-black);
			input,
			textarea {
				background-color: var(--bs-dk-black);
				color: var(--bs-white);
			}
			label {
				color: var(--bs-white);
				&:after {
					background: var(--bs-dk-black);
				}
			}
		}

		/*** DISABLED ***/
		&.disabled {
			border-color: var(--bs-dk-grey);
			background: transparent;
			label {
				color: var(--bs-dk-grey);
			}
			input {
				background: transparent;
				color: var(--bs-dk-grey);
			}
		}
	}
	.social-icons {
		font-size: 1.3em;
		a {
			margin-right: 5px;
			padding: 4px;
			display: inline-block;
		}
	}
}

.chart-container {
	z-index: 2;
	padding-bottom: 5px;
	.legend {
		font-size: 0.9em;
		.marker {
			content: " ";
			width: 15px;
			height: 15px;
			display: inline-block;
			border-radius: 50%;
			margin-bottom: -3px;
		}
		.complete {
			.marker {
				background: var(--bs-red);
			}
		}
		.incomplete {
			.marker {
				background: var(--bs-dk-grey);
			}
		}
	}
	canvas {
		height: 100% !important;
		min-width: 100% !important;
	}
}

.event-sidebar-nav {
	min-height: 100vh;
	padding-top: 40px;
	background-color: var(--bs-dk-black);
	box-shadow: 5px 2px 22px rgba(0, 0, 0, 0.45);
	max-width: 300px;
	@media (max-width: $tablet) {
		min-height: initial;
		padding: 6px 12px 4px 12px;
		box-shadow: none;
		max-width: initial;
	}

	.event-title {
		font-weight: 700;
		font-size: 1.1em;
		margin: 0;
		line-height: 1.2;
	}

	.eventMenu {
		font-size: 1.3em;
		display: flex;
	}

	.link {
		text-decoration: none;
		color: #fff;
		background-color: var(--bs-dk-black);
		width: 100%;
		display: block;
		font-size: 1em;
		display: flex;
		align-items: center;
		padding: 10px 4px;
		text-transform: capitalize;
		border-radius: 10px;
		transition: 0.1s ease-in-out;
		margin-bottom: 7px;

		&.active,
		&:hover,
		&:focus,
		:active {
			background-color: var(--bs-black);
		}
		.fa-stack {
			color: var(--bs-black);
			margin-right: 4px;
			.fa-inverse {
				color: var(--bs-lt-red);
			}
		}
		.title {
			font-size: 0.85em;
			font-weight: 700;
			padding-top: 4px;
		}
	}

	.accordion-menu {
		margin-top: 15px;
		margin-bottom: 40px;

		@media (max-width: $tablet) {
			margin-top: 0px;
			margin-bottom: 0px;
			visibility: hidden;
			opacity: 0;
			z-index: -1;
			max-height: 0px;
			transition: 0.15s ease-in-out;
			&.show {
				margin-top: 10px;
				visibility: visible;
				opacity: 1;
				z-index: 5;
				max-height: 1600px;
			}
		}

		.accordion-item {
			background-color: initial;
			border: none;
			margin-top: 10px;
		}
		.accordion-button {
			background-color: initial;
			color: var(--bs-white);
			display: flex;
			align-items: center;
			justify-content: space-between;
			outline: none;
			box-shadow: none;
			font-family: $body-font-family;
			padding: 8px 0px;
			&:after {
				display: none;
				font: var(--fa-font-solid);
				content: "\f107";
			}
			svg {
				transform: rotate(0deg);
				transition: 0.2s ease-in-out;
			}
			&.collapsed {
				svg {
					transform: rotate(180deg);
				}
			}
		}
		.accordion-body {
			color: var(--bs-white);
			padding: 0;
			padding-left: 12px;
			margin: 7px 0;
			border-left: 2px solid var(--bs-lt-red);
			margin-left: 10px;
			@media (max-width: $tablet) {
				margin-left: 5px;
			}
			.sub-menu {
				padding: 0;
				margin: 0;
				list-style: none;
				a {
					text-decoration: none;
					color: #fff;
					background-color: var(--bs-dk-black);
					width: 100%;
					display: block;
					font-size: 1em;
					display: flex;
					align-items: center;
					padding: 6px 4px;
					text-transform: capitalize;
					border-radius: 10px;
					transition: 0.1s ease-in-out;
					margin-bottom: 5px;
					cursor: pointer;

					&.active,
					&:hover,
					&:focus,
					:active {
						background-color: var(--bs-black);
						color: #fff;
					}
					.fa-stack {
						color: var(--bs-black);
						margin-right: 4px;
						.fa-inverse {
							color: var(--bs-lt-red);
						}
					}
					.title {
						font-size: 0.85em;
						font-weight: 700;
						padding-top: 4px;
					}
				}
			}
		}
	}
}

.ticket-menu {
	ul {
		list-style: none;
		overflow: auto;
		white-space: nowrap;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			a {
				background-color: var(--bs-lt-black);
				color: var(--bs-white);
				border-radius: 8px;
				margin-right: 12px;
				font-size: 1em;
				line-height: 1.3;
				text-decoration: none;
				text-align: center;
				min-height: 100px;
				min-width: 110px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				line-height: 1.6;
				transition: 0.15s ease-in-out;
				@media (max-width: $tablet) {
					min-height: 80px;
					min-width: 80px;
					font-size: 0.9em;
				}

				&.active,
				&:hover,
				&:focus,
				:active {
					background-color: var(--bs-dk-black);
					color: #fff;
				}

				.icon {
					display: block;
				}
			}
		}
	}
}

.board {
	padding-top: 39px;
	padding-left: 40px;
	padding-bottom: 200px;
	max-width: 1700px;
	@media (max-width: $tablet) {
		padding-top: 30px;
		padding-left: calc(var(--bs-gutter-x) * 0.5);
		padding-bottom: 70px;
	}
	.small-container {
		max-width: 900px;
	}
}
.event-sidebar-nav + .board {
	max-width: 1000px;
}

.block {
	margin-top: 20px;
	padding: 25px;
	border: 1px solid #1a1a1a;
	border-radius: 18px;
	background-color: var(--bs-lt-black);
	p:last-child {
		margin-bottom: 0px;
	}
	.input-block {
		background: var(--bs-lt-black);
		label {
			background: var(--bs-lt-black);
			&:after {
				background: var(--bs-lt-black);
			}
		}
		input {
			background-color: var(--bs-lt-black);
		}
	}
	@media (max-width: $mobile) {
		margin-top: 12px;
		padding: 25px 20px;
	}

	.date-block {
		min-width: 40px;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		.day {
			font-size: 1.5em;
			color: var(--bs-white) !important;
		}
		.month {
			font-size: 0.9em;
			margin-top: -10px;
			font-weight: 700;
			color: var(--bs-lt-red);
		}
	}
	.event-title {
		color: var(--bs-white) !important;

		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		display: -webkit-box;
		-webkit-box-orient: vertical;

		line-height: 1.3em;
	}

	.multiple-select-container {
		.select-btn {
			&.open {
				background-color: var(--bs-lt-black);
			}
		}
		.btn-label,
		.list-items {
			background-color: var(--bs-lt-black);
		}
	}
}

.btn {
	padding: 18px 20px 13px 20px;
	font-size: 13px;
	line-height: 1em;
	font-weight: 700;
	border: none;
	transition: 0.2s ease-in-out;

	@media (max-width: $mobile) {
		padding: 16px 16px 12px 16px;
	}

	&.primary-btn {
		color: var(--bs-black);
		background-color: var(--bs-lt-red);
		&:hover,
		&:focus,
		:active {
			color: var(--bs-black);
			background-color: var(--bs-red);
		}
	}
	&.secondary-btn {
		color: var(--bs-white);
		background-color: var(--bs-lt-black);
		&:hover,
		&:focus,
		:active {
			color: var(--bs-white);
			background-color: var(--bs-dk-black);
		}
	}
}

.pills {
	margin-top: 25px;
	.pill {
		background: var(--bs-lt-red);
		padding: 5px 9px 5px 15px;
		margin-right: 10px;
		margin-bottom: 10px;
		border-radius: 8px;
		display: inline-flex;
		font-size: 0.9em;
		font-weight: 700;
		line-height: 1em;
		.text {
			display: flex;
			align-self: center;
			line-height: 1.1em;
			padding-top: 2px;
			color: var(--bs-black);
		}
		.mark-close {
			padding: 6px;
			display: flex;
			color: var(--bs-black);
		}
	}
}

.form-switch {
	.form-check-label {
		padding-top: 3px;
		font-size: 0.95em;
	}
	.form-check-input {
		margin-top: 5px;
	}
}

.ticket-table {
	padding: 10px;
	font-size: 15px;
	@media (max-width: $tablet) {
		font-size: 13px;
	}
	@media (max-width: $mobile) {
		font-size: 12px;
	}
	& > .heading {
		font-family: $heading-font-family;
		text-transform: capitalize;
		padding: 13px 7px;
		background-color: var(--bs-dk-black);
		border-radius: 12px;
		border-bottom: 1px solid #222222;
		border-right: 1px solid #3e3e3e;
		font-size: 13px;
		letter-spacing: 0.02em;

		@media (max-width: $tablet) {
			display: none;
		}
		@media (max-width: $mobile) {
			font-size: 11px;
		}
	}
	&.attendee-list {
		@media (max-width: $tablet) {
			overflow-x: scroll;
			& > .heading,
			.event-row {
				min-width: 950px;
			}
		}
	}

	/*** ORDER DETAILS LIST***/

	&.order-details-list {
		@media (max-width: $tablet) {
			overflow-x: scroll;
			& > .heading,
			.event-row {
				min-width: 700px;
			}
		}
	}

	.event-row {
		padding: 13px 7px;
		background-color: var(--bs-lt-black);
		border-radius: 12px;
		margin-top: 10px;
		border-bottom: 1px solid #222222;
		border-right: 1px solid #3e3e3e;
		transition: 0.2s ease-in-out;

		@media (max-width: $tablet) {
			padding: 20px 5px;
		}

		> .col-10 {
			cursor: pointer;
		}

		&:hover {
			background-color: var(--bs-dk-black);
			border-right: 1px solid #202020;
			border-bottom: 1px solid #202020;
		}
		.date {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 0.8em;
			.day {
				font-size: 1.8em;
				display: block;
				color: var(--bs-white) !important;
			}
			.month {
				margin-top: -10px;
				display: block;
				font-weight: 700;
				color: var(--bs-lt-red);
			}
		}
		.thumbnail {
			display: flex;
			align-items: center;
			img {
				border-radius: 12px;
				min-height: 90px;
				max-height: 90px;
			}
		}
		.event-info {
			display: flex;
			flex-direction: column;
			font-size: 0.75em;
			justify-content: center;
			color: var(--bs-white) !important;
			@media (max-width: $tablet) {
				font-size: 0.9em;
			}
			.heading {
				font-weight: 700;
				font-size: 1.3em;
				text-transform: capitalize;
				margin-bottom: 5px;
				line-height: 1.4em;
				color: var(--bs-white) !important;
			}
			.location {
				color: var(--bs-white) !important;
				.location-name {
					font-weight: 700;
				}
			}
			.full-date {
				color: var(--bs-white) !important;
			}
		}
		.revenue-info,
		.status-info {
			line-height: 1em;
			color: var(--bs-white) !important;
			margin-top: 9px;
		}
		.sold-info {
			color: var(--bs-white) !important;
		}
		.mobile-event-block {
			.info {
				strong {
					font-size: 1.22em;
				}
			}
			.d-flex {
				.info {
					margin-right: 17px;
					padding-right: 17px;
					border-right: 1px solid var(--bs-white);
					display: grid;
					@media (max-width: $mobile) {
						margin-right: 13px;
						padding-right: 13px;
					}
					&:last-child {
						margin-right: 0px;
						padding-right: 0px;
						border-right: 0;
					}
					.heading {
						margin-bottom: 1px;
					}
				}
			}
		}
		.ticket-type-name {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.progress {
	--bs-progress-bar-bg: var(--bs-red);
}

.dropdown {
	position: relative;
	display: flex;

	.dropdown-button {
		transition: 0.2s ease-in-out;
		color: var(--bs-lt-red);
		background: none;
		border: 0;
		text-align: left;
		font-size: 0.9em;
		&:hover,
		&:focus,
		:active {
			color: var(--bs-red);
		}

		.fa-stack {
			pointer-events: none;
			.fa-ellipsis-vertical {
				color: var(--bs-black);
			}
		}
		svg {
			pointer-events: none;
		}
	}

	/***** remove select on dropdown select ****/
	&.select {
		.dropdown-button {
			color: var(--bs-lt-grey);
			padding: 6px 12px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14.4px;

			@media (max-width: $mobile) {
				padding: 0.25rem 0.75rem;
			}

			&:hover,
			&:focus,
			:active {
				color: var(--bs-white);
			}
			& + .dropdown-menu {
				left: 0px;
				right: initial;
			}

			/****** FONT AWESOME ******/
			&::after {
				display: none;
			}

			&::after {
				font: var(--fa-font-solid);
				content: "\f107";
			}
		}

		svg {
			transition: 0.2s ease-in-out;
		}
	}

	.select-block {
		width: 100%;
		position: relative;

		&::after {
			display: none;
		}

		&::after {
			font: var(--fa-font-solid);
			content: "\f0dc";
		}
		svg {
			color: var(--bs-lt-grey);
			position: absolute;
			right: 13px;
			top: 8px;
			pointer-events: none;
			transition: 0.15s ease-in-out;
			font-size: 0.9em;
		}

		select {
			width: 100%;
			padding: 5px 15px;
			outline: 0;
			background: var(--bs-black);
			color: var(--bs-lt-grey);
			border: 0;
			transition: 0.15s ease-in-out;
			border-radius: 10px;
			&:focus {
				color: var(--bs-white);
				& + svg {
					color: var(--bs-white);
				}
			}

			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			position: relative;

			option {
				&:disabled {
					background: var(--bs-black);
					color: var(--bs-dk-grey);
				}
			}
		}
	}

	&.active {
		svg {
			transform: rotate(-180deg);
		}

		& > .dropdown-button + .dropdown-menu {
			opacity: 1;
			transform: translateY(0);
			pointer-events: auto;
		}
	}

	.dropdown-menu {
		position: absolute;
		right: 0px;
		top: calc(100% + 0.25rem);
		padding: 0;
		border-radius: 10px;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
		opacity: 0;
		pointer-events: none;
		transform: translateY(-10px);
		transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
		background: none;
		display: grid;
	}
	.dropdown-links {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		max-height: 215px;
		border-radius: 10px;
		/***** SCROLL BAR ****/
		// &::-webkit-scrollbar {
		// 	width: 7px;
		// 	border-radius: 7px;
		// }

		/* Track */
		// &::-webkit-scrollbar-track {
		// 	background: var(--bs-grey);
		// 	border-radius: 5px;
		// }

		/* Handle */
		// &::-webkit-scrollbar-thumb {
		// 	background: var(--bs-dk-grey);
		// 	border-radius: 5px;
		// }

		/* Handle on hover */
		// &::-webkit-scrollbar-thumb:hover {
		// 	background: var(--bs-black);
		// }

		/*** SCROLL BAR CUSTOM***/
		@include scrollbar;

		.link {
			background: var(--bs-lt-black);
			color: var(--bs-white);
			border: none;
			text-decoration: none;
			font-family: inherit;
			font-size: inherit;
			cursor: pointer;
			padding: 12px 15px;
			padding-top: 15px;
			transition: 0.1s ease-in-out;
			line-height: 1.25;

			&:hover,
			&:focus,
			:active {
				background: var(--bs-black);
			}

			&:first-child {
				border-top-left-radius: 10px;
			}
			&:last-child {
				border-bottom-left-radius: 10px;
			}
		}
	}
}

/******* ORDER PAGE ******/

.btn-group {
	margin-right: 8px;

	.btn {
		border: 2px solid var(--bs-grey);
		padding: 16px;
		color: var(--bs-white);
		display: flex;
		align-items: center;
		border-radius: 8px !important;
		font-size: 1em;
	}
	.order-checkbox {
		margin: 0;
		pointer-events: none;
	}
	span {
		margin-bottom: -2px;
		margin-top: 2px;
		margin-left: 6px;
		font-size: 0.95em;
	}
}

.order-filters-modal {
	@media (max-width: $tablet) {
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 100%;
		width: 100%;
		padding: 0 15px;
		background-color: #00000087;
		&.show {
			opacity: 1;
			visibility: visible;
			z-index: 100;
		}
		.content {
			background-color: var(--bs-black);
			position: relative;
			top: 46%;
			transform: translateY(-50%);
			border-radius: 6px;
			padding: 20px 15px;
		}
	}
}

.report-filters {
	position: absolute;
	top: 52px;
	left: 0;
	width: 290px;
	background-color: var(--bs-lt-black);
	border-radius: 12px;
	padding: 8px;
	padding-top: 0px;

	transition: 0.15s ease-in-out;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
	z-index: -1;

	&.active {
		visibility: visible;
		pointer-events: initial;
		opacity: 1;
		z-index: 100;
	}

	.block {
		margin-top: 8px;
		background-color: var(--bs-black);
		padding: 15px;
		text-align: center;
		aspect-ratio: 16/12;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
		transition: 0.15s ease-in-out;
		border-radius: 12px;

		&.active,
		&:hover,
		&:focus,
		:active {
			background-color: var(--bs-dk-black);
		}

		.icon {
			font-size: 1.6em;
			margin-bottom: 2px;
		}

		.title {
			font-size: 13px;
		}
	}
}

.multiple-select-container {
	position: relative;
	margin-top: 20px;
	.list-items {
		position: absolute;
		top: 40px;
		left: 0;
	}
}

.select-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 12px 7px 15px;
	border-radius: 8px;
	cursor: pointer;
	border: 2px solid var(--bs-grey);
	color: var(--bs-grey);
	transition: 0.15s ease-in-out;
	.btn-label {
		position: absolute;
		top: -11px;
		left: 10px;
		padding: 0px 5px;
		background: var(--bs-black);
		transition: 0.15s ease-in-out;
	}
	&.open {
		border-color: var(--bs-white);
		outline: 0;
		background-color: var(--bs-black);
		.btn-label {
			color: var(--bs-white);
			top: -9px;
			font-size: 14px;
		}
		~ .list-items {
			display: block;
			width: 100%;
			z-index: 99;
		}
	}
}
.list-items {
	position: relative;
	margin-top: 15px;
	border-radius: 10px;
	padding: 0px;
	background-color: var(--bs-black);
	display: none;
	border: 2px solid var(--bs-grey);
	transition: all 0.2s ease-in-out;
	z-index: -1;
	overflow: hidden;
	&:hover {
		border: 2px solid var(--bs-white);
	}
	.item {
		label {
			display: flex;
			align-items: center;
			list-style: none;
			height: 45px;
			cursor: pointer;
			transition: 0.3s;
			padding: 0 11px;
			border-radius: 8px;
			color: var(--bs-grey);
			&:hover {
				background-color: var(--bs-dk-black);
				.item-text {
					color: var(--bs-white);
				}
			}
			&:has(.form-check-input:checked) {
				background-color: var(--bs-dk-black);
				color: var(--bs-white);
			}
			.form-check-input {
				margin-top: 3px;
				font-size: 0.95em;
				cursor: pointer;
				margin-right: 7px;
				&:checked {
					color: var(--bs-white);
				}
			}
		}

		/***** POTENTIONAL REMOVE******/

		.item-text {
			color: var(--bs-grey);
			font-size: 14px;
			margin-top: 1px;
		}
		.checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 16px;
			width: 16px;
			border-radius: 4px;
			margin-right: 6px;
			border: 1.5px solid #c0c0c0;
			transition: all 0.2s ease-in-out;
			.check-icon {
				color: #fff;
				font-size: 11px;
				transform: scale(0);
				transition: all 0.2s ease-in-out;
			}
		}
		&.checked {
			background-color: var(--bs-dk-black);
			.checkbox {
				background-color: var(--bs-red);
				border-color: var(--bs-red);
			}
			.check-icon {
				transform: scale(1);
			}
		}

		/***** END POTENTIONAL REMOVE******/
	}
}

/******** CREATE EVENT ********/
.ai-modal {
	.modal-dialog {
		padding: 8px;
		.modal-body {
			padding-top: 55px;
			padding-bottom: 10px;
			padding-right: 20px;
			padding-left: 20px;

			.close {
				position: absolute;
				top: 20px;
				right: 22px;
				font-size: 30px;
				@media (max-width: $mobile) {
					font-size: 28px;
				}
			}
			h3 {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-bottom: 2px;
				@media (max-width: $tablet) {
					font-size: 1.7em;
				}
			}
			.ai-progress {
				position: absolute;
				top: 28px;
				left: 50%;
				transform: translateX(-50%);
				color: var(--bs-grey);
				pointer-events: none;
				font-size: 13px;
				letter-spacing: 3px;
				font-weight: 700;
				opacity: 0.85;
			}
		}
	}
}

.checkbox-group {
	margin-top: 12px;
	.btn-check ~ label,
	.primary-btn {
		padding: 17px 18px 14px 18px;
		line-height: 1em;
		background-color: var(--bs-lt-black);
		border: 2px solid var(--bs-grey);
		color: var(--bs-grey);
		width: 100%;

		@media (max-width: $mobile) {
			padding: 17px 10px 14px 10px;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: var(--bs-red);
			color: var(--bs-dk-black);
			border: 2px solid var(--bs-lt-red);
		}
	}
	.btn-check:checked ~ label {
		background-color: var(--bs-lt-red);
		border: 2px solid var(--bs-lt-red);
		color: var(--bs-dk-black);
	}
}

.google-maps {
	position: relative;
	padding-bottom: 45%;
	height: 0;
	overflow: hidden;
	border-radius: 12px;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.banner {
	border-radius: 10px;
	background-color: var(--bs-lt-black);
	margin-top: 30px;
	margin-bottom: 16px;
	border: 2px dashed var(--bs-dk-grey);
	cursor: pointer;
	transition: 0.15s ease-in-out;
	color: var(--bs-grey);
	max-height: 275px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	aspect-ratio: 16 / 8;
	overflow: hidden;
	width: 100%;

	.content {
		text-align: center;
		font-size: 1.2em;
		img {
			border-radius: 10px;
		}
		.fa-image {
			font-size: 4em;
			margin-bottom: 10px;
		}
	}

	&:hover {
		color: var(--bs-white);
		border: 2px dashed var(--bs-white);
	}
}

.summary-block {
	display: flex;
	.tools {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 20px;
		padding-right: 10px;
		.icon {
			margin-top: 8px;
			font-size: 1.1em;
			background-color: var(--bs-lt-black);
			height: 40px;
			width: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			cursor: pointer;
			border: 1px solid var(--bs-dk-grey);
			transition: 0.15s ease-in-out;
			color: var(--bs-grey);

			&.active,
			&:hover,
			&:focus {
				border: 1px solid var(--bs-white);
				color: var(--bs-white);
			}

			@media (max-width: $mobile) {
				font-size: 1em;
				height: 37px;
				width: 37px;
			}
		}
	}

	.embed-video {
		width: 100%;
		.youtube-video {
			aspect-ratio: 16 / 9;
			width: 100%;
			border-radius: 10px;
			overflow: hidden;
			width: 100% !important;
			height: initial !important;
		}
	}
}

.description-buttons {
	padding: 20px 26px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	background-color: var(--bs-lt-black);
	color: var(--bs-grey);
	border: 1px solid var(--bs-dk-grey);
	border-radius: 10px;
	transition: 0.15s ease-in-out;
	margin-bottom: 15px;
	text-decoration: none;
	flex-wrap: wrap;
	margin: 6px;
	cursor: pointer;

	.fa-solid-text-circle-plus,
	.fa-solid-image-circle-plus,
	.fa-solid-video-circle-plus {
		font-size: 1.8em;
		margin-bottom: 10px;
	}

	&.active,
	&:hover,
	&:focus,
	&:active {
		color: var(--bs-white);
		border: 1px solid var(--bs-white);
	}
	@media (max-width: $mobile) {
		font-size: 0.88em;
		padding: 16px 18px;
		margin: 1px;
	}
}

.group-heading {
	margin-top: 5px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	border-bottom: 2px dashed var(--bs-dk-grey);
	margin-bottom: 18px;
}
.group-section {
	margin-top: 10px;
	.group-row {
		background-color: var(--bs-lt-black);
		color: var(--bs-white);
		border: 2px solid var(--bs-lt-black);
		padding: 18px 15px;
		border-radius: 8px;
		position: relative;
		box-shadow: 6px 6px 9px 0px #00000026;
		z-index: 10;
		& > .row {
			align-items: center;
			.move {
				display: inline-flex;
				margin-right: 6px;
			}
			.group-name {
				padding-top: 3px;
				display: inline-flex;
				flex-direction: column;
				.title {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
	.tickets {
		.ticket-row {
			background-color: var(--bs-lt-black);
			margin-top: 0px;
			margin-right: 25px;
			margin-left: 25px;
			border-top: 1px solid var(--bs-black);
			margin-bottom: 1px;

			@media (max-width: $mobile) {
				margin-right: 18px;
				margin-left: 18px;
			}

			&:first-child {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				border-top: initial;
			}
			&:last-child {
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}
	}

	&.coupon-section {
		font-size: 14px;
		@media (max-width: $tablet) {
			font-size: 16px;
		}
		.group-name {
			width: 97%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			.title {
				display: block !important;
			}
		}
	}
}

.one-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.emptyContainer {
	.child {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 200px;
	}
}

.ticket-row {
	background-color: var(--bs-lt-black);
	padding: 13px 21px;
	border-radius: 8px;
	position: relative;

	& > .row {
		align-items: center;
		.move {
			display: inline-flex;

			margin-right: 10px;
		}
		.group-name {
			padding-top: 3px;
			display: inline-flex;
			border-left: 2px dashed #202020;
			padding-left: 10px;
			line-height: 1em;
			flex-direction: column;
			.title {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			small {
				margin-top: 5px;
				display: block;
				font-size: 0.88em;
				line-height: 1.5;
			}
		}
	}
	&:before,
	&:after {
		content: "";
		display: inline-block;
		position: absolute;
		height: 28px;
		width: 26px;
		border-radius: 50%;
		background: #1e1e1e;
		top: 50%;
		transform: translateY(-50%);
	}
	&:before {
		left: -15px;
		right: initial;
	}
	&:after {
		left: initial;
		right: -15px;
	}
}

.event-block {
	min-height: 250px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px;
	@media (max-width: $mobile) {
		font-size: 0.9em;
	}
	.background-image {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		z-index: 2;
		overflow: hidden;
		width: 100%;
		border-radius: 10px;

		img {
			max-height: 250px;
			object-fit: cover;
			object-position: center center;
			border-radius: 12px;
		}
	}
	.overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgb(0, 0, 0);
		background: linear-gradient(
			180deg,
			rgba(30, 30, 30, 0.45) 0%,
			var(--bs-black) 100%
		);
		width: 100%;
		height: 100%;
		z-index: 3;
	}
	.date {
		z-index: 5;
		color: white;
		position: relative;
		display: flex;
		justify-content: end;
		.slab {
			background: #000;
			padding: 8px 12px;
			border-radius: 10px;
			font-size: 0.75em;
			text-align: center;
			.day {
				font-size: 1.7em;
				line-height: 1em;
			}
			.month {
				line-height: 1em;
			}
		}
	}
	.content {
		z-index: 5;
		color: white;
		position: relative;
		font-size: 0.9em;
		.organizer {
			.fa-stack {
				font-size: 0.7em;
				margin-top: -4px;
				color: var(--bs-primary);
				.fa-check {
					padding-top: 1px;
				}
			}
		}
		.event-name {
			font-size: 1.4em;
			font-weight: 700;
		}
	}
	&.publish {
		margin-top: 24px;

		&:hover div,
		&:focus div,
		&:active div {
			color: #fff;
		}
		&:not(:first-of-type) {
			margin-top: 30px;
		}
	}
}

.sales-block {
	display: flex;
	min-height: 130px;
	align-items: center;
	background: var(--bs-redwood);
	border-radius: 10px;
	padding: 12px;
	@media (max-width: $mobile) {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}
	.icon {
		display: flex;
		align-items: center;
		width: 70px;
		font-size: 35px;
		justify-content: center;
		transition: 0.2s ease-in-out;
		@media (max-width: $mobile) {
			width: 60px;
			font-size: 30px;
			margin-bottom: 10px;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		.title {
			font-weight: 700;
		}
		.stat {
			font-size: 26px;
			@media (max-width: $mobile) {
				font-size: 13px;
			}
		}
	}
	&:hover,
	&:focus,
	&:active {
		.icon {
			scale: 1.005;
			font-size: 38px;
			transform: rotate(3deg) scale(1.2);
		}
	}
}

.overview-events {
	position: relative;
	list-style: none;
	overflow-x: auto;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	width: 100%;
	padding-bottom: 15px;
	margin-bottom: 10px;
	margin-top: 7px;

	.overview-block {
		display: block;
		border-radius: 10px;
		box-shadow: 3px 3px 5px var(--bs-dk-black);
		transition: 0.2s ease-in-out;
		cursor: pointer;
		scale: 1;
		color: inherit;
		margin-right: 20px;
		max-width: 370px;
		min-width: 370px;
		@media (max-width: $mobile) {
			max-width: 300px;
			min-width: 80vw;
		}
		&:last-child {
			margin-right: 0;
			max-width: initial;
			min-width: initial;
		}
		.background-image img {
			min-height: 100%;
		}
		.content {
			.event-name {
				white-space: initial;
				line-height: 1.2;

				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
		}
		.overlay {
			background: var(--bs-black);
			opacity: 0.75;
			transition: 0.2s ease-in-out;
		}

		&:hover,
		&:focus,
		&:active,
		&.active {
			box-shadow: 6px 6px 10px var(--bs-dk-black);
			scale: 1.01;
			color: inherit;
			* {
				color: inherit;
			}
			.fa-check {
				color: var(--bs-white);
			}
			.overlay {
				background: var(--bs-black);
				opacity: 0.48;
			}
		}

		.create-event {
			justify-content: center;
			width: 100%;
			padding: 0 40px;
			font-size: 1.2em;
			.overlay {
				background: var(--bs-lt-red);
				transition: 0.2s ease-in-out;
			}
			.text {
				margin-top: 7px;
				text-align: center;
			}
			&:hover,
			&:focus,
			:active {
				.overlay {
					background: var(--bs-red);
					opacity: 0.75;
				}
			}
		}
	}
}

.modal {
	.modal-content {
		background-color: var(--bs-black);
		border-radius: 15px;
		.modal-header {
			border-bottom-color: var(--bs-dk-grey);
			h1 {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			@media (max-width: $mobile) {
				padding-top: 12px;
				padding-bottom: 12px;
			}
			.close {
				font-size: 25px;
				display: flex;
			}
		}
		.banner {
			aspect-ratio: 16/7;
		}
		.modal-footer {
			border-top-color: var(--bs-dk-grey);

			@media (max-width: $mobile) {
				padding-top: 5px;
				padding-bottom: 5px;
			}
		}
	}
}
.attendee-block-container {
	.attendee-block {
		margin-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid var(--bs-lt-black);
		.heading {
			margin-bottom: -9px;
		}
	}
	> :first-child {
		margin-top: 18px;
	}
	> :last-child {
		padding-bottom: 0;
		border-bottom: none;
	}
}
.payment-radio-container {
	.payment-radio {
		display: block;
		padding: 18px;
		border: 1px solid var(--bs-dk-grey);
		border-bottom: 0;
		padding-bottom: 12px;
		.form-check {
			display: flex;
			justify-content: space-between;
			padding: 0 10px;
			align-items: center;
			padding: 0;
		}
		.form-check-input {
			margin-top: 0px;
		}
	}
	> :first-child {
		margin-top: 8px;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}
	> :last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		border-bottom: 1px solid var(--bs-dk-grey);
	}
}

.schedule-tabs {
	position: relative;
	list-style: none;
	overflow-y: hidden;
	overflow-x: auto;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	width: 100%;
	padding-bottom: 15px;
	margin-bottom: 10px;
	margin-top: 7px;
	cursor: grab;
	margin-top: 30px;
	display: flex;
	flex-wrap: initial;
	border: 0;

	/***** SCROLL BAR ****/
	// &::-webkit-scrollbar {
	// 	height: 5px;
	// 	border-radius: 7px;
	// }

	/* Track */
	// &::-webkit-scrollbar-track {
	// 	background: var(--bs-grey);
	// 	border-radius: 5px;
	// }

	/* Handle */
	// &::-webkit-scrollbar-thumb {
	// 	background: var(--bs-dk-grey);
	// 	border-radius: 5px;
	// }

	/* Handle on hover */
	// &::-webkit-scrollbar-thumb:hover {
	// 	background: var(--bs-black);
	// }

		/*** SCROLL BAR CUSTOM***/
		@include scrollbar;

	.nav-item {
		display: block;
		margin-right: 8px;

		.nav-link {
			color: var(--bs-white);
			line-height: 1.2em;
			border-radius: 10px;
			background-color: var(--bs-dk-black);
			margin-top: 3px;
			padding: 0;
			height: 86px;
			width: 86px;
			max-height: 86px;
			max-width: 86px;
			padding-right: 1px;
			padding-top: 3px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.day {
				display: block;
				font-size: 22px;
				font-weight: 700;
			}
			.month {
				font-size: 16px;
				display: block;
			}

			.analytics-block {
				.icon {
					font-size: 1.2em;
					margin-bottom: 5px;
				}
				.title {
					font-weight: 700;
					font-size: 0.7em;
				}
			}

			&.active {
				color: var(--bs-black);
				background-color: var(--bs-lt-red);
				border-color: var(--bs-lt-red);
			}
			&:hover,
			&:focus {
				color: var(--bs-black);
				background-color: var(--bs-lt-red);
				border-color: var(--bs-dk-grey);
			}
		}
	}
}

.ticket-modal-tab {
	border-bottom-color: var(--bs-lt-red);

	.nav-link {
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: var(--bs-lt-red);
			color: var(--bs-black);
			border-color: var(--bs-lt-red);
		}
		color: var(--bs-white);
		margin-right: 6px;
		border-color: var(--bs-lt-red);
		font-weight: 700;
	}
}

.tab-pane {
	margin-top: 20px;
}

.item-profile {
	background-color: var(--bs-black);
	border-radius: 12px;
	padding: 15px 7px;
	display: flex;
	color: var(--bs-white) !important;

	&:hover,
	&:focus,
	&:active {
		background-color: var(--bs-dk-black);
		.name,
		.handle {
			color: var(--bs-white);
		}
	}
	.icon {
		color: var(--bs-lt-red);
		.d-block {
			line-height: 1;
		}
	}
	.profile-image {
		width: 70px;
		padding-right: 11px;
		img {
			border-radius: 50%;
		}
	}
	.profile-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		.handle {
			font-size: 0.85em;
		}
	}

	&.dark {
		background-color: var(--bs-dk-black);
		&:hover,
		&:focus,
		&:active {
			background-color: #000;
		}
	}
}

.order-details {
	.title {
		font-size: 0.9em;
		color: var(--bs-grey);
	}
	.content {
		font-weight: 700;
		font-size: 1.1em;
		.fa-stack {
			font-size: 0.55em;
			margin-top: -4px;
			color: var(--bs-primary);
		}
		small {
			display: block;
			font-weight: 400;
			font-size: 0.8em;
			line-height: 1.15;
			color: var(--bs-lt-grey);
		}
	}
	.notes-author {
		.name {
			font-weight: 700;
			small {
				color: var(--bs-grey);
				font-weight: 400;
				font-size: 0.8em;
			}
		}
		p {
			color: var(--bs-lt-grey);
			font-weight: 400;
			font-size: 0.9em;
		}
	}
}

.calendar {
	display: flex;
	margin-top: 50px;
	.hours-col {
		width: 62px;
		text-align: end;
		.hour {
			height: 50px;
			border-bottom: 1px solid grey;
			position: relative;
			text-transform: uppercase;
			font-size: 11px;
			border-right: 1px solid grey;
			&:first-child {
				height: 30px;
			}
			span {
				position: absolute;
				bottom: -11px;
				right: 10px;
				z-index: 5;
				background-color: var(--bs-black);
				width: calc(100% - 10px);
				margin-left: 10px;
				padding-right: 7px;
			}
		}
	}
	.entries-col {
		height: 100%;
		width: 100%;
		position: relative;
		.hour-space {
			height: 50px;
			border-bottom: 1px solid grey;
			&:first-child {
				height: 30px;
			}
		}
		.clickable-entry {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
		}
		.rooms {
			position: absolute;
			top: 30px;
			left: 0;
			width: 100%;
			font-size: 11px;
			line-height: 1.3em;
			height: 100%;
			.room-names {
				font-size: 12px;
				font-weight: 700;
				text-align: center;
				margin-top: -70px;
				position: sticky;
				top: 0;
				background-color: var(--bs-black);
				z-index: 6;
				padding: 15px 0;
			}
			.entry-row {
				height: 100%;
				.col {
					border-right: 1px solid grey;
					&:last-child {
						border-right: none;
					}
				}
			}
			.entries-container {
				position: relative;
				.entry-block {
					position: absolute;
					width: 100%;
					background-color: var(--bs-lt-black);
					padding: 10px;
					border-radius: 10px;
					margin-top: 2px;
					margin-bottom: 2px;
					overflow: hidden;
					z-index: 10;
					.title {
						font-weight: 700;
					}
					.time {
						@media (max-width: $mobile) {
							font-size: 9px;
						}
					}
				}
			}
		}
	}
}

.pricing-container {
	position: relative;
	.pricing-grid {
		position: relative;
		z-index: 9;
		padding-top: 100px;
		padding-bottom: 80px;
		pointer-events: none;
		.info-row {
			border-bottom: 1px solid #ddddddb5;
			padding-bottom: 5px;
			margin-bottom: 18px;
			@media (max-width: $mobile) {
				font-size: 12px;
			}
			.info {
				pointer-events: all;
			}
			.fa-x {
				color: #ffffff4f;
			}
		}
	}
	.pricing-options {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		.option {
			background-color: var(--bs-lt-black);
			min-height: 100%;
			border-radius: 12px;
			position: relative;
			padding-left: 5px;
			padding-right: 5px;
			transition: 0.18s ease-in-out;
			&.selected,
			&:hover,
			&:focus,
			&:active {
				background-color: #bf4848;
				color: var(--bs-white);
			}
			.title {
				padding-top: 32px;
				padding-bottom: 12px;
				font-weight: 700;
				font-size: 0.9em;
				text-align: center;
				border-bottom: 2px dashed var(--bs-lt-grey);
			}
			&:before,
			&:after {
				content: "";
				display: inline-block;
				position: absolute;
				height: 34px;
				width: 34px;
				border-radius: 50%;
				background: #1e1e1e;
				left: 50%;
				transform: translateX(-50%);
			}
			&:before {
				top: -18px;
				left: 50%;
				right: initial;
			}
			&:after {
				bottom: -18px;
				left: 50%;
				right: initial;
			}
			.bottom {
				border-bottom: 2px dashed var(--bs-lt-grey);
				content: "";
				height: 2px;
				position: absolute;
				bottom: 52px;
				left: 5px;
				width: calc(100% - 10px);
			}
		}
	}
}

input[type="date"].form-control,
input[type="time"].form-control {
	border: none;
	box-sizing: border-box;
	outline: 0;
	position: relative;
	width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
	background: none;
	bottom: 0;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

.progress-container {
	width: 100%;
	margin: 1.5em auto;
	.progress-steps {
		counter-reset: step;
		display: flex;
		padding: 0;
		margin: 0;
		li {
			list-style-type: none;
			width: 25%;
			font-size: 0.7em;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			color: var(--bs-grey);
			font-weight: 700;
			&:before {
				width: 2.7em;
				height: 2.7em;
				content: counter(step);
				counter-increment: step;
				line-height: 2.5em;
				border: 2px solid var(--bs-grey);
				display: block;
				text-align: center;
				margin: 0 auto 0.7em auto;
				border-radius: 50%;
				background-color: var(--bs-black);
				font-weight: 700;
			}
			&:after {
				width: 100%;
				height: 2px;
				content: "";
				position: absolute;
				background-color: var(--bs-grey);
				top: 1.3em;
				left: -50%;
				z-index: -1;
			}
			&:first-child:after {
				content: none;
			}
			&.complete {
				color: var(--bs-red);
				&:before {
					background: var(--bs-red);
					border-color: var(--bs-red);
					color: var(--bs-black);
				}
				&:after {
					background-color: var(--bs-red);
				}
			}
		}
	}
}

.progress-steps {
	li.active {
		color: var(--bs-red);

		&:before {
			border-color: var(--bs-red);
		}
		&:after {
			background-color: var(--bs-red);
		}
	}
}

.home-search-filters {
	display: flex;
	column-gap: 15px;
	.search {
		max-width: 270px;
	}
	.mobile-search {
		width: 100%;
	}
	@media (max-width: $tablet) {
		flex-direction: column;
		gap: 0px;
		.search {
			max-width: initial;
		}
		.mobile-search {
			display: flex;
			width: 100%;
			.btn-group {
				.btn {
					white-space: nowrap;
					padding: 0 15px;
				}
			}
		}
	}
}

.row-blocks {
	position: relative;
	list-style: none;
	overflow-x: auto;
	white-space: nowrap;
	padding: 0;
	margin: 0;
	width: 100%;
	padding-bottom: 15px;
	margin-bottom: 10px;
	margin-top: 7px;
	display: flex;
	cursor: grab;

	/***** SCROLL BAR ****/
	// &::-webkit-scrollbar {
	// 	height: 5px;
	// 	border-radius: 7px;
	// }

	/* Track */
	// &::-webkit-scrollbar-track {
	// 	background: var(--bs-grey);
	// 	border-radius: 5px;
	// }

	/* Handle */
	// &::-webkit-scrollbar-thumb {
	// 	background: var(--bs-dk-grey);
	// 	border-radius: 5px;
	// }

	/* Handle on hover */
	// &::-webkit-scrollbar-thumb:hover {
	// 	background: var(--bs-black);
	// }

	/*** SCROLL BAR CUSTOM***/
	@include scrollbar;


	.block {
		display: block;
		border-radius: 10px;
		transition: 0.15s ease-in-out;
		scale: 1;
		color: inherit;
		margin-right: 20px;
		max-width: 260px;
		min-width: 260px;
		white-space: initial;
		margin-top: 10px;
		@media (max-width: $tablet) {
			max-width: 235px;
			min-width: 235px;
		}
		.profile {
			display: flex;
			justify-content: center;
			img {
				border-radius: 50%;
				width: 80px;
			}
		}
		.bio {
			font-size: 0.9em;
			color: var(--bs-grey);
		}
		.cta {
			.following {
				background-color: var(--bs-black);
				color: var(--bs-white);
			}
		}
	}
}

.notices {
	margin-top: 10px;
	.received-block {
		display: flex;
		padding: 35px 15px;
		border-bottom: 1px solid var(--bs-dk-grey);
		@media (max-width: $mobile) {
			padding: 28px 10px;
			font-size: 0.95em;
		}
		.profile {
			img {
				width: 70px;
				border-radius: 50%;
				margin-right: 15px;
				@media (max-width: $mobile) {
					width: 50px;
				}
			}
		}
		.content {
			width: 100%;
			.ticket-name {
				font-size: 1.05em;
				small {
					font-size: 0.8em;
				}
			}
		}
		.icons {
			display: flex;
			margin-top: -2px;
			margin-left: -6px;
			.message {
				color: var(--bs-white);
				padding: 8px;
				margin-bottom: -15px;
				svg {
					&.fa-messages {
						color: #007bff !important;
					}
					&.fa-heart {
						color: #ff0000 !important;
					}
				}
				span {
					font-size: 0.95em;
				}
			}
		}
		.time {
			width: 100px;
			text-align: right;
		}
	}
}

.order-info {
	.organizer {
		display: flex;
		margin-top: 28px;
		.profile {
			display: flex;
			align-items: center;
			width: 100%;
			img {
				width: 70px;
				margin-right: 8px;
				border-radius: 50%;
				@media (max-width: $mobile) {
					width: 50px;
				}
			}
			.name {
				display: inline-block;
				font-weight: 700;
				.fa-stack {
					font-size: 0.7em;
					margin-top: -4px;
					color: var(--bs-primary);
				}
				svg.fa-check {
					padding-top: 1px;
					padding-right: 1px;
				}
			}
		}
	}
	.announcement {
		border: 1px solid var(--bs-dk-grey);
		border-radius: 12px;
		padding: 19px 15px 15px 15px;
		p {
			margin: 0;
		}
	}
	.tool {
		text-align: center;
		font-size: 1.3em;
		.title {
			font-size: 0.8em;
			margin-top: 6px;
			color: var(--bs-white) !important;
			@media (max-width: $mobile) {
				font-size: 0.62em;
			}
		}
	}
	.tickets {
		position: relative;
		list-style: none;
		overflow-x: auto;
		white-space: nowrap;
		padding: 0;
		margin: 0;
		width: 100%;
		padding-bottom: 15px;
		margin-bottom: 10px;
		margin-top: 7px;
		display: flex;
		cursor: grab;
		margin-top: 30px;
		display: flex;

		/***** SCROLL BAR ****/
		// &::-webkit-scrollbar {
		// 	height: 5px;
		// 	border-radius: 7px;
		// }

		/* Track */
		// &::-webkit-scrollbar-track {
		// 	background: var(--bs-grey);
		// 	border-radius: 5px;
		// }

		/* Handle */
		// &::-webkit-scrollbar-thumb {
		// 	background: var(--bs-dk-grey);
		// 	border-radius: 5px;
		// }

		/* Handle on hover */
		// &::-webkit-scrollbar-thumb:hover {
		// 	background: var(--bs-black);
		// }

		/*** SCROLL BAR CUSTOM***/
		@include scrollbar;

		.ticket {
			background-color: var(--bs-dk-black);
			padding: 40px 20px 40px 20px;
			border-radius: 12px;
			margin-right: 20px;
			white-space: initial;
			min-width: 280px;
			max-width: 280px;
			font-size: 0.9em;
			position: relative;
			overflow: hidden;
			.qr {
				text-align: center;
				img {
					width: 140px;
					border-radius: 5px;
				}
			}
			h5 {
				font-size: 1.2em;
			}
			.ticket-name {
				line-height: 1.2em;
			}
			&:before,
			&:after {
				content: "";
				background: var(--bs-black);
				height: 40px;
				width: 40px;
				border-radius: 50%;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				pointer-events: none;
			}
			&:before {
				top: -20px;
			}
			&:after {
				bottom: -20px;
			}
			hr {
				border-style: dashed;
			}
		}
	}
	.event-info {
		margin-top: 30px;
		> * {
			margin-top: 15px;
			&:first-child {
				margin-top: 0;
			}
		}
	}
}

.user-profile {
	.account {
		text-align: center;
		img {
			margin-bottom: 20px;
			border-radius: 50%;
			width: 120px;
		}
		.handle {
			margin-bottom: 10px;
		}
		.bio {
			font-size: 0.88em;
			max-width: 400px;
			margin: 0 auto;
		}
	}
	.user-tickets {
		display: block;
		width: 100%;
		border-top: 1px solid var(--bs-dk-grey);
		border-bottom: 1px solid var(--bs-dk-grey);
		text-align: center;
		margin-top: 20px;
		padding: 20px 0;
		font-size: 1.05em;
		color: var(--bs-white) !important;
	}
}

.navbar {
	position: fixed;
	z-index: 10;
	padding: 10px;
	display: flex;
	width: 100%;
	background-color: #0f0f0fac;

	@media (max-width: $mobile) {
		background-color: #0f0f0fdf;
	}

	.navbar-logo {
		display: flex;
		text-decoration: none;
		img {
			max-width: 50px;
		}
		.name {
			display: flex;
			align-items: center;
			line-height: 1;
			margin-top: 5px;
			margin-left: 10px;
			color: var(--bs-white);
		}
	}
	.main-menu {
		display: flex;
		padding: 0;
		margin: 0;
		margin-left: auto;
		list-style: none;

		li {
			display: flex;
			align-items: center;
			a {
				margin-right: 15px;
				color: var(--bs-white);
				text-decoration: none;
				&.btn {
					padding: 10px 15px;
				}
			}
		}

		@media (max-width: $mobile) {
			position: absolute;
			top: 0;
			left: 0;
			background-color: #000;
			height: 100vh;
			width: 100%;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			z-index: -1;
			transition: 0.2s ease-in-out;
			opacity: 0;
			visibility: hidden;

			&.show {
				opacity: 1;
				visibility: visible;
			}

			li {
				margin-bottom: 15px;
			}
			.btn {
				background: none;
				padding: initial !important;
				border: none;
			}
			a {
				font-size: 25px;
				color: var(--bs-white);
				font-weight: 700;
				margin-right: 0 !important;
			}
		}
	}
}

.hamburgerEvent {
	display: none;
	cursor: pointer;
	@media (max-width: $mobile) {
		display: inline-block;
	}

	.bar-line {
		border-radius: 10px;
	}

	.bar1,
	.bar2,
	.bar3 {
		width: 27px;
		height: 4px;
		background-color: #fff;
		margin: 5px 0;
		transition: 0.2s ease-in-out;
		margin-left: auto;
	}

	.bar1 {
		width: 27px;
	}
	.bar2 {
		width: 21px;
	}
	.bar3 {
		width: 24px;
	}

	&.change .bar1 {
		width: 27px;
		transform: translate(0, 9px) rotate(135deg);
	}

	&.change .bar2 {
		width: 27px;
		opacity: 0;
	}

	&.change .bar3 {
		width: 27px;
		transform: translate(0, -9px) rotate(-135deg);
	}
}

.hero {
	display: flex;
	align-items: end;
	overflow: hidden;
	position: relative;
	min-height: 86vh;
	@media (max-width: $tablet) {
		padding-top: 82px;
		padding-bottom: 50px;
	}
	.background-image {
		position: absolute;
		top: 50%;
		left: 50%;
		object-fit: cover;
		width: 100%;
		transform: translate(-50%, -50%);
		z-index: 1;
		pointer-events: none;
		img {
			min-height: 86vh;
			@media (max-width: $tablet) {
			}
		}
	}
	.overlay {
		background: rgb(33, 33, 33);
		background: linear-gradient(
			0deg,
			rgb(30 30 30) 10%,
			rgba(30, 30, 30, 0.45) 100%
		);
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}
	.container {
		display: flex;
		position: relative;
		@media (min-width: $tablet) {
			max-width: initial;
		}
		@media (max-width: $tablet) {
			flex-direction: column;
			.content {
				order: 1;
			}
		}
	}
	.featured-image {
		z-index: 3;
		margin-top: 10px;
		padding-bottom: 5px;
		@media (max-width: $mobile) {
			padding-bottom: 0px;
		}
		img {
			border-radius: 15px;
			aspect-ratio: 6/4;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 900px;
		margin-right: 50px;
		z-index: 3;
		padding: 80px 100px;
		@media (max-width: $tablet) {
			width: 100%;
			margin-right: 0px;
			min-width: initial;
			padding: initial;
		}
		.tagline {
			font-size: 0.85em;
			font-weight: 700;
			color: var(--bs-lt-grey);
			text-transform: uppercase;
			letter-spacing: 3px;
			margin-bottom: 10px;
		}
	}

	&.single-event {
		padding: 100px 0 20px 0;
		min-height: initial;
		.background-images {
			img {
				filter: blur(10px);
			}
		}
		.container {
			flex-direction: column;
			.content {
				margin-right: 0;
				display: block;
				padding: 0;
			}
		}
		.organizer {
			display: block;
			margin-top: 12px;
			margin-bottom: 5px;
			color: white;
			text-decoration: none;
			.fa-stack {
				font-size: 0.6em;
				margin-top: -4px;
				color: var(--bs-primary);
			}
		}
		.date,
		.location,
		.price {
			display: block;
			margin-bottom: 7px;
		}
		@media (max-width: $mobile) {
			padding-top: 68px;
			.container {
				padding: 0;
				.content {
					padding: 0 12px;
				}
			}
		}
	}

	&.landing-page {
		.container {
			max-width: 1320px;
			.content {
				max-width: 625px;
				padding-right: 0px;
				@media (max-width: $tablet) {
					margin: 0 auto;
				}
			}
		}
		.hero-image {
			z-index: 4;
			display: flex;
			justify-content: center;
			padding-right: 100px;
			@media (max-width: $tablet) {
				padding-right: 0px;
			}
			img {
				border-radius: 12px;
				@media (max-width: $tablet) {
					max-width: 400px;
					margin-bottom: 20px;
				}
			}
		}
	}
	&.cta {
		min-height: 420px;
		margin-top: 80px;
		@media (max-width: $tablet) {
			min-height: 300px;
			margin-top: 40px;
		}
		.overlay {
			background-color: var(--bs-black);
			opacity: 0.9;
		}
	}
}

.mapboxgl-map {
	border-radius: 12px;
}

.single-event .container,
.single-event-body .container {
	max-width: 800px;
}

.login-hero {
	min-height: 100vh;
	display: flex;
	.image-background {
		width: 55%;
		position: relative;
		&::before {
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(
				-90deg,
				rgb(30 30 30) 0%,
				rgb(30 30 30 / 0%) 100%
			);
			margin-left: 1px;
		}
		img {
			min-height: 100%;
		}
	}
	& > .content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		margin: 0 auto;
		max-width: 500px;
		.login-form {
			width: 100%;
			z-index: 0;
			padding: 30px;
		}
	}
	@media (max-width: $tablet) {
		display: block;
		.image-background {
			width: 100%;
			img {
				min-height: 38vh;
			}
			&::before {
				background: linear-gradient(
					0deg,
					rgb(30 30 30) 0%,
					rgb(30 30 30 / 0%) 100%
				);
				margin-left: 0px;
			}
		}
		.content {
			.login-form {
				padding: 10px;
			}
		}
	}
	.or-block {
		margin-bottom: -10px;
		margin-top: 12px;
	}

	/**** THANK YOU SCREEN ****/
	&.thank-you {
		& > .content {
			max-width: 700px;
			align-items: initial;
			padding: 20px 0;
			.login-form {
				border-radius: 20px;
				margin-top: 60px;
				.fa-badge-check {
					font-size: 4em;
					margin-bottom: 20px;
				}
			}
			.confirmation-content {
				background-color: var(--bs-dk-black);
				padding: 30px 25px;
				border-radius: 10px;
				@media (max-width: $tablet) {
					padding: 22px 20px;
				}
				hr {
					border-top-style: dashed;
				}
				a {
					text-decoration: underline;
				}
			}
		}
	}
}

.index-events-container {
	padding-bottom: 50px;
	position: relative;
	& > .overlay {
		content: "";
		height: 100%;
		width: 100%;
		background: var(--bs-dk-black);
		background: linear-gradient(
			90deg,
			var(--bs-transparent) 85%,
			var(--bs-black) 100%
		);

		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
	}
}

.index-events {
	position: relative;
	list-style: none;
	overflow-x: auto;
	white-space: nowrap;
	padding: 2px;
	margin: 0;
	width: 100%;
	padding-bottom: 20px;
	margin-top: 7px;
	cursor: grab;

	/***** SCROLL BAR ****/
	// &::-webkit-scrollbar {
	// 	height: 5px;
	// 	border-radius: 7px;
	// }

	/* Track */
	// &::-webkit-scrollbar-track {
	// 	background: var(--bs-grey);
	// 	border-radius: 5px;
	// }

	/* Handle */
	// &::-webkit-scrollbar-thumb {
	// 	background: var(--bs-dk-grey);
	// 	border-radius: 5px;
	// }

	/* Handle on hover */
	// &::-webkit-scrollbar-thumb:hover {
	// 	background: var(--bs-black);
	// }

	/*** SCROLL BAR CUSTOM***/
	@include scrollbar;


	.index-block {
		display: block;
		border-radius: 10px;
		box-shadow: 3px 3px 5px var(--bs-dk-black);
		transition: 0.2s ease-in-out;
		scale: 1;
		color: inherit;
		margin-right: 20px;
		text-decoration: none;
		min-width: 380px;
		position: relative;

		&:active {
			cursor: grab;
		}

		@media (max-width: $mobile) {
			max-width: 300px;
			min-width: 80vw;
		}
		&:last-child {
			margin-right: 0;
		}
		.background-image {
			overflow: initial;
			img {
				min-height: 100%;
			}
		}
		.content {
			.event-name {
				white-space: initial;
				line-height: 1.2;
			}
		}
		.overlay {
			background: rgb(33, 33, 33);
			background: linear-gradient(
				0deg,
				rgb(30 30 30) 40%,
				rgb(30 30 30 / 30%) 100%
			);
			opacity: 0.9;
			border-radius: 10px;
			transition: 0.2s ease-in-out;
		}
		&:hover,
		&:focus,
		&:active,
		&.active {
			box-shadow: 6px 6px 10px var(--bs-dk-black);
			scale: 1.01;
			color: inherit;
			* {
				color: inherit;
			}
			.fa-check {
				color: var(--bs-white);
			}
			.overlay {
				transform: scale(1.01);
				opacity: 0.4;
			}
		}
	}
}

.call-out {
	display: flex;
	margin-bottom: 75px;
	padding: 0 10%;
	@media (max-width: $tablet) {
		padding: initial;
	}

	&.img-right {
		img {
			order: 2;
			width: 64%;
			border-radius: 12px;
			margin-left: -16%;
			margin-right: 0%;
			@media (max-width: $tablet) {
				width: 100%;
				margin: 0;
				order: 0 !important;
			}
		}
	}

	@media (max-width: $tablet) {
		flex-direction: column;
		margin-bottom: 0px;
	}
	img {
		width: 64%;
		border-radius: 12px;
		margin-right: -16%;
		aspect-ratio: 6/5;
		@media (max-width: $tablet) {
			width: 100%;
			margin: 0;
		}
	}
	.content {
		padding: 30px;
		background-color: rgba(22, 22, 22, 0.63);
		border-radius: 12px;
		z-index: 2;
		backdrop-filter: blur(10px);
		@media (max-width: $tablet) {
			background-color: var(--bs-black);
			padding: 20px 0px 35px 0px;
		}
		p {
			margin-bottom: 3px;
		}
	}
}

.testimonials {
	.stars {
		.fa-star {
			margin-right: 4px;
			color: #ffe65b;
		}
	}
	h4 {
		margin-top: 15px;
		margin-bottom: 10px;
	}
}

.ticket-sales {
	.ticket {
		display: flex;
		margin-top: 15px;
		position: relative;
		.ticket-border {
			min-height: 100%;
			min-width: 4px;
			background-image: linear-gradient(
				45deg,
				rgba(66, 66, 66, 0.5) 25%,
				transparent 25%,
				transparent 50%,
				rgba(66, 66, 66, 0.5) 50%,
				rgba(66, 66, 66, 0.5) 75%,
				transparent 75%,
				transparent
			);
			background-color: var(--bs-lt-black);
			background-size: 1rem 1rem;
			animation: animatedPole 3s linear infinite;
			animation-play-state: paused;
			transition: 0.2s ease-in-out;
		}

		&.active {
			.ticket-border {
				background-color: var(--bs-red);
				animation-play-state: running;
			}
		}

		.content {
			padding: 15px;
			background: var(--bs-dk-black);
			border-radius: 12px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			.header {
				display: flex;
				width: 100%;
				margin-bottom: 18px;
				.title {
					display: flex;
					align-items: center;
					padding-right: 20px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-size: 1.1em;
					font-weight: 700;
				}
				.count {
					display: flex;
					align-items: center;
					width: 170px;
					.dropdown {
						margin: 0;
					}
				}
			}
			p {
				font-size: 12px;
				margin-top: 10px;
				margin-bottom: 0;
			}
			.input-block {
				label {
					background: var(--bs-dk-black);
					&:after {
						background: var(--bs-dk-black);
					}
				}
				&:focus-within {
					background-color: var(--bs-dk-black);
					input,
					textarea {
						background-color: var(--bs-dk-black);
					}
					label {
						&:after {
							background: var(--bs-dk-black);
						}
					}
				}
			}
			.dropdown-menu {
				min-width: initial;
				.dropdown-links {
					width: 74px;
				}
			}

			&.active {
				border-color: var(--bs-red);
			}
		}
		&::after {
			background-color: var(--bs-black);
			height: 27px;
			width: 27px;
			content: "";
			border-radius: 50%;
			position: absolute;
			right: -16px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.ticket-group {
		margin-top: 20px;
		border-top: 1px solid var(--bs-dk-grey);
		padding-top: 3px;
		.group-title {
			text-transform: capitalize;
			font-weight: 700;
			margin-top: 15px;
			margin-bottom: -2px;
		}
		.ticket {
			padding-left: 20px;
			@media (max-width: $mobile) {
				padding-left: 13px;
			}
		}
	}
}

@keyframes animatedPole {
	0% {
		background-position-x: 2rem;
	}
}

footer {
	margin-top: 20px;
	padding: 50px 0;
}

.ticket-footer {
	position: fixed;
	bottom: 0;
	padding: 15px;
	background: var(--bs-dk-black);
	width: 100%;
	display: flex;
	justify-content: center;
	opacity: 0;
	z-index: -1;
	visibility: hidden;

	transition: 0.15s ease-in-out;

	.btn {
		padding: 13px 35px 10px 35px;
	}

	&.active {
		opacity: 1;
		z-index: 10;
		visibility: visible;
	}
}

.reel {
	position: relative;
	margin-top: 8px;
	border-radius: 20px;
	overflow: hidden;
	.video-block {
		position: initial;
		top: 0;
		left: 0;
		video {
			width: 100%;
		}
	}
	.thumbnail {
		z-index: 3;
		display: flex;
		justify-content: center;
		align-items: center;
		aspect-ratio: 9 / 16;
		position: absolute;
		width: 100%;
		height: 100%;

		opacity: 1;
		visibility: visible;
		transition: 0.2s ease-in-out;
		img {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 4;
		}
		.icon {
			z-index: 5;
			font-size: 1.7em;
			.fa-stack {
				transition: 0.15s ease-in-out;
				color: var(--bs-black);
				margin-right: 4px;
				.fa-inverse {
					transition: 0.2s ease-in-out;
					color: var(--bs-lt-red);
				}
			}
		}
	}
	&:hover {
		.thumbnail {
			.fa-stack {
				color: transparent;
				scale: 2;
				.fa-inverse {
					color: var(--bs-black);
				}
			}
		}
	}
	&.active {
		.thumbnail {
			scale: 1.8;
			opacity: 0;
			visibility: hidden;
		}
	}
}

/***** ADDED APP CSS *****/
.mapboxgl-map {
	border-radius: 12px;
}

.venue-location {
	max-height: 175px;
	margin-top: 5px;
	margin-bottom: 30px;
	li {
		cursor: pointer;
		&.active,
		&:hover,
		&:focus,
		:active {
			background-color: var(--bs-black);
		}
	}
}
