.stepper__step {
  border: 2px solid var(--bs-grey) !important;
  cursor: default !important;
}


.stepper__step + div > span {
  color: var(--bs-grey) !important;
  font-size: 0.8em;
  text-transform: uppercase;
  line-height: 2.5em;
  text-align: center;
  margin: 0 auto .7em auto;
  background-color: var(--bs-black) !important;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 700;
}

.paginationBtn {
  color: var(--bs-lt-red);
  cursor: pointer;
}

.paginationDisableBtn {
  color: var(--bs-lt-grey);
}

.paginationDisableBtn :active{
  color: var(--bs-lt-grey) !important;
}

.eventlistheight {
  height: auto;
  min-height: 39rem;
}

@media (max-width: 765px) {
  .eventlistheight {
      min-height: 34rem;
  }
}