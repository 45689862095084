.page-item .page-link {
  background: #000;
  color: var(--bs-lt-red);
  border: 0.5px solid var(--bs-lt-red);
  font-weight: 600;
}

.page-item:first-child .page-link {
  background: #000;
  color: var(--bs-lt-red);
  border: 0.5px solid var(--bs-lt-red);
}

.page-item .page-link:hover {
  background: #000;
  color: var(--bs-lt-red);
  border: 0.5px solid var(--bs-lt-red);
  font-weight: 600;
}

.page-item:last-child .page-link {
  background: #000;
  color: var(--bs-lt-red);
  border: 0.5px solid var(--bs-lt-red);
}

.active>.page-link, .page-link.active {
  background: var(--bs-lt-red) !important;
  color: var(--bs-lt-black) !important;
}

.page-link {
  background: #000 !important;
  color: var(--bs-lt-red) !important;
  border: 0.5px solid var(--bs-lt-red) !important;
}