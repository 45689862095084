.clearable-input {
  position: relative;
  display: inline-block;
}

.clearable-input>input {
  padding-right: 1.4em;
}

.clearable-input>[data-clear-input] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  font-size: 1.4em;
  padding: 0.5em;
  cursor: pointer;
}

.clearable-input>input::-ms-clear {
  display: block;
}

/* .list-group {
  height: 150px;
} */

::placeholder {
  color: var(--bs-grey) !important;
  opacity: 1 !important;
  /* Firefox */
}