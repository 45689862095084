.rbc-toolbar {
  display: none;
}

.rbc-label {
  font-size: 11px;
  margin-top: 2px;
  padding-right: 31px;
}

.rbc-header {
  font-size: 12px;
  font-weight: 700;
  padding: 5px;
  border-bottom: 1px solid #fff;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 0;
}

.rbc-event {
  background-color: #2a2a2a;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #2a2a2a;
  width: 103%;
  font-size: 0.9rem;
}

.rbc-time-view-resources .rbc-time-header-gutter {
  background-color: #1e1e1e;
}

.rbc-timeslot-group {
  background-color: #1e1e1e;
  border-bottom: 1px solid #858585;
}

.rbc-row-content {
  background-color: #1e1e1e;
}

.rbc-time-view {
  border: 0px solid #ddd;
}

/* .rbc-time-view .rbc-allday-cell {
  display: none;
} */

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  border-right: 1px solid #858585;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #858585;
}

.rbc-time-content {
  border-top: 1px solid #858585;
}
