.btnAccept {
  background-color: green !important;
  width: 50%;
}

.btnReject {
  background-color: red !important;
  width: 50%;
}

.btnBack {
  width: 50%;
}